/** @jsx jsx */
import { Box, jsx, Grid } from "theme-ui";
import React, { useCallback } from "react";
import { PageContentNonMemoized } from "gatsby-plugin-hfn-profile/components/PageContentNonMemoized";
import LayoutHome from "../layout/LayoutHome";
import { eventCallbackTriggers } from "../templates/wppage";

const DonationKanhaBuilding = () => {
  const pageContent = (id, modalTitle) => {
    const titletext = modalTitle || "Donation for Green Kanha";
    return `<gatsby_donation donationId = "${id}" btntext = "${titletext}"  btnBg = #4d8f15 colortext = black colorprimary = forestgreen colorsecondary = darkgreen colorbackground = white colormuted = #f6f6f6 colorhighlight = #efeffe titletext = "Donation for Kanha Building" allowOverseas = false/>`;
  };

  const PageContent = React.memo(PageContentNonMemoized);

  const eventCallback = useCallback(
    (event) => eventCallbackTriggers("Donation_for_Green_Kanha", event),
    []
  );

  const renderContentHeader = () => (
    <>
      <Box
        sx={{
          backgroundColor: "rgb(223, 242, 246)",
          textAlign: "center!important",
          marginBottom: "30px",
          padding: "40px 0px",
          border: "1px solid rgb(211, 213, 210)",
        }}
      >
        <h1 sx={{ fontSize: "1.7em" }}>Heartfulness Donations</h1>
      </Box>
    </>
  );
  return (
    <LayoutHome
      seoTitle="Donation - Kanha Building - Donations"
      contentHeader={renderContentHeader()}
    >
      <Grid columns={1}>
        <Box>
          <h1
            sx={{
              fontSize: "1.5em",
              marginBottom: "0.5rem",
              fontWeight: "600",
            }}
          >
            Donation for SMSF India – General Fund – Kanha Building
          </h1>

          <p>
            <b>Welcome!</b> Thank you for choosing to donate to Sahaj Marg
            Spirituality Foundation, India.
          </p>
          <p>
            Sahaj Marg Spirituality Foundation supports all the events and
            programs undertaken by <b>heartfulness</b>.
          </p>
          <p>
            On completion of the donation, a confirmation email will be sent to
            you with a completed donation form attached.
          </p>
          <p>
            <b>
              Please forward this mail back to
              <a
                href="mailto:info.accounts@sahajmarg.org"
                sx={{ marginLeft: "5px", marginRight: "5px" }}
              >
                info.accounts@sahajmarg.org
              </a>
              along with its attachment as your confirmation of the donation.
            </b>
          </p>
          <p>
            <b>There are no refunds for the donations.</b>
          </p>
          <p>
            Donation to this fund would be used specially for the construction
            works at Kanha Ashram in Hyderabad, India.
          </p>
        </Box>
        <Box>
          <PageContent
            eventCallback={eventCallback}
            pageContent={pageContent(3, "Contribute Now")}
          />
        </Box>
      </Grid>
    </LayoutHome>
  );
};

export default DonationKanhaBuilding;
